import React, {useState} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import InputMask from "react-input-mask";
import {ProgressBar} from "react-bootstrap";
import vys from "../../../const/api";


function FreeDonate(props) {
    const [FullName, setFullName ] = useState('');
    const [nameError, setNameError] = useState(false);
    const [NameProduct, setNameProduct ] = useState('');
    const [nameProductError, setNameProductError] = useState(false);
    const [Address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);
    const [Phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [Note, setNote] = useState('');
    const [NoteError, setNoteError] = useState(false);
    const [fileList , setFileList] = useState([]);
    const [showSuccess, setshow] = useState(false);
    const [progress, setProgress] = useState(0);

    const showmessage = (set) => {
        set(true)
        setTimeout(function () {
            set(false)
        }, 2000);
    }
    const inputs = [
        { n: FullName, error: nameError, setError: setNameError, setValue: setFullName, placeholder: 'Ad, Soyad Ata adı *', type: 'text' },
        { n: NameProduct, error: nameProductError, setError: setNameProductError, setValue: setNameProduct, placeholder: 'Məhsulun adı *', type: 'text' },
        { n: Address, error: addressError, setError: setAddressError, setValue: setAddress, placeholder: 'Ünvan *', type: 'text' }
    ]
    const inputPhone = [
        { n: Phone, error: phoneError, setError: setPhoneError, setValue: setPhone, placeholder: 'Telefon nömrəsi *', type: 'phone' },
    ]
    const inputNote = [
        { n: Note, error: NoteError, setError: setNoteError, setValue: setNote, placeholder: 'Qeyd', type: 'text' },
    ]
    const inputValidation = () => {
        let sendM = false
        inputs.map((i) => {
            if (i.n.trim() === null || i.n.trim() === "" || i.n === " ") {
                showmessage(i.setError)
                sendM = false
            } else {
                sendM = true
            }
        })
        inputPhone.map((input2) => {
            if (input2.n.trim() === null || input2.n.trim() === "" || input2.n === " ") {
                showmessage(input2.setError)
                sendM = false
            } else {
                sendM = true
            }
        })
        sendMessage(sendM)
    }
    const onsubmit = (e) => {
        e.preventDefault()
        inputValidation()
        deleteFile()

    }
    const sendMessage = (sendM) => {
        if (sendM) {
            vys
                .post("FreeSupport", {
                    FullName,
                    NameProduct,
                    Phone,
                    Address,
                    Note,
                    Images: fileList.filter(fileLists => fileLists.type === "image/jpeg"),
                    // subjectId: Number(subjectId),
                })
                .then((res) => {
                    showmessage(setshow)
                    setFullName('');
                    setNameProduct('')
                    setPhone('');
                    setAddress('');
                    setNote('');
                })
                .catch((err) => {
                    console.log(err)
                });
        }
    }
    const deleteFile = (i) => {
        let arr = [...fileList]
        arr.splice(i , 1)
        setFileList(arr)
    }
    const onUpload = e => {
        const index = fileList.length;
        let inserted = false;
        let form_data = new FormData();
        form_data.append("_file", e.target.files[0]);
        const files = [...fileList];
        files.push({
            uploadedFile: false
        });
        setFileList(files);
        vys
            .post("Upload/File", form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },

                onUploadProgress: data => {
                    const percent = Math.round(90 * data.loaded / data.total);
                    setProgress(percent);
                },
            })
            .then((res) => {
                const arr = [...fileList];
                arr.splice(index, 1, {
                    ...res.data,
                    uploadedFile: true,
                });
                setProgress(0);
                setFileList(arr);
            })
            .catch((err) => {
                console.log(err)
            });
    }
    return (
        <div>
            <div className="section-contact main position-relative">
                <div className="container-fluid">
                    <div className="d-flex justify-content-end cancel">
                        <Link to="/">
                            <img src={Image.Close} alt=""/>
                        </Link>
                    </div>
                    <div className="text-center contact-text">
                       {/*<div>*/}
                       {/*    <p>Pulsuz ver</p>*/}
                       {/*     <span>*/}
                       {/*         Pulsuz ver – Burada Siz istənilən əşyaların (məişət əşyaları, paltar, ayaqqabı, oyuncaq və sair …)*/}
                       {/*         şəkillərini yerləşdirərib və müvafiq məlumat yazaraaq onları eytiyac sahiblərinə təmanasız olaraq verə bilərsiniz.*/}
                       {/*     </span>*/}
                       {/*</div>*/}
                        <>
                            <form onSubmit={onsubmit}>
                                {showSuccess ? <h1 className="text-white animated fadeIn"><strong>Müraciətiniz qəbul olunmuşdur</strong></h1> : <h1><strong>Pulsuz ver</strong></h1>}
                                <span>
                                    Pulsuz ver – Burada Siz istənilən əşyaların (məişət əşyaları, paltar, ayaqqabı, oyuncaq və sair …)
                                    şəkillərini yerləşdirərib və müvafiq məlumat yazaraaq onları eytiyac sahiblərinə təmanasız olaraq verə bilərsiniz.
                                </span>
                                <div>
                                    {inputs.map((inputs) => (
                                        <div className="position-relative">
                                            <div>
                                                <input placeholder={inputs.placeholder} value={inputs.n} className={`${inputs.error ? 'error' : 'mb-3'}`} type={inputs.type} onChange={(e) =>
                                                {inputs.setValue(e.target.value)}} />
                                                {inputs.error &&
                                                    <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span>}
                                            </div>
                                        </div>
                                    ))}
                                    {inputPhone.map((inputPhone) => (
                                        <div className="position-relative">
                                            <div>
                                                <InputMask placeholder={inputPhone.placeholder} {...props} mask="+\9\9\4 99 999 99 99" maskChar="" value={inputPhone.n} className={`${inputPhone.error ? 'error' : 'mb-3'}`} type={inputPhone.type} onChange={(e) =>
                                                {inputPhone.setValue(e.target.value)}} />
                                                {inputPhone.error &&
                                                    <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span>}
                                            </div>
                                        </div>
                                    ))}
                                    {inputNote.map((inputs) => (
                                        <div className="position-relative">
                                            <div>
                                                <input placeholder={inputs.placeholder} value={inputs.n} className={`${inputs.error ? 'error' : 'mb-3'}`} type={inputs.type} onChange={(e) =>
                                                {inputs.setValue(e.target.value)}} />
                                                {inputs.error &&
                                                    <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span>}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="contact-link d-md-flex justify-content-center">
                                    <label className="upload-file mb-md-0 mb-3" htmlFor="upload-photo">Şəkil əlavə etmək</label>
                                    <input disabled={progress} onChange={(e) => {
                                        onUpload(e)
                                    }} type="file" name="cv" id="upload-photo" />
                                    <button type='submit' >Göndər</button>
                                </div>
                                <div className="file-upload-box">
                                    {fileList.length > 0 &&
                                        fileList.map((f, i)=>(
                                            <React.Fragment key={i}>
                                                <div className="animated zoomIn progress-file d-flex justify-content-between align-items-center p-3 my-2">
                                                    <div className="uploaded-file mr-4 ">
                                                        {f.uploadedFile ? <img src={f.url} alt=""/> : <img src={Image.Avatar} alt=""/>}
                                                    </div>
                                                    <div className="mr-4 w-100">
                                                        {f.uploadedFile ? (
                                                            <ProgressBar now={100} label={`100%`} />
                                                        ) : (
                                                            <ProgressBar now={progress} label={`${progress}%`} />
                                                        )}
                                                    </div>
                                                    <div onClick={()=>{deleteFile(i)}} className="delete-file">
                                                        <div>
                                                            <img src={Image.Close} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            </form>
                        </>



                    </div>
                </div>
            </div>
        </div>
    );
}

export default FreeDonate;
