import React, { useRef, useState, useEffect } from "react";
import Image from "../../../assets/img/Images";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Slider from "react-slick";
import Toast from "react-bootstrap/Toast";
import InputMask from "react-input-mask";
import Social from "../../Elements/Social/Social";
import { connect } from "react-redux";
import { getSupportFull } from "../../../redux/actions";

function DonateNow(props) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const { supportFull, getSupportFull } = props;
  const { id } = props.match.params;
  const [showA, setShowA] = useState(false);

  const toggleShowA = () => setShowA(!showA);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
    getSupportFull(id);
  }, [id]);

  const next = () => {
    slider2.current.slickNext();
  };
  const previous = () => {
    slider2.current.slickPrev();
  };
  return (
    <div>
      <div className="main position-relative">
        <div className="container-fluid">
          <div className="row mt-5 pt-5 mb-5">
            <div className="col-lg-6 col-12 animated fadeInLeftBig position-rel">
              <div className="section-text">
                <h2 className="m-0">{supportFull?.title}</h2>
                <span>{supportFull?.description}</span>
              </div>
              <div>
                <div className="range-percent d-flex justify-content-between align-items-center">
                  <p>Total collection</p>
                  <span>
                    {Math.floor(
                      (supportFull.accumulatedAmount /
                        supportFull.neededAmount) *
                        100
                    )}{" "}
                    %
                  </span>
                </div>
                <Form>
                  <Form.Group controlId="formBasicRange">
                    <Form.Control
                      type="range"
                      value={Math.round(
                        (supportFull.accumulatedAmount /
                          supportFull.neededAmount) *
                          100
                      )}
                    />
                  </Form.Group>
                </Form>
                <div className="d-flex justify-content-between range-about">
                  <p>Collection: ${supportFull.accumulatedAmount}</p>
                  <p>Goal: ${supportFull.neededAmount}</p>
                </div>
              </div>
              <div className="donate-number d-flex justify-content-between">
                <div>
                  <p>79</p>
                  <span>Donors</span>
                </div>
                <div>
                  <p>$14.000.000</p>
                  <span>Donated</span>
                </div>
                <div>
                  <p>14</p>
                  <span>Anonymously</span>
                </div>
              </div>
              <div>
                <button
                  onClick={toggleShowA}
                  className="donate-form-button text-white"
                >
                  Donate
                </button>

                <Toast show={showA} onClose={toggleShowA}>
                  <Toast.Body>
                    <div className="donate-form">
                      <form action="">
                        <div className="row mt-2">
                          <div className="col-xl-6 col-12 mb-xl-0 mb-3">
                            <input
                              className="w-100"
                              type="text"
                              placeholder="Ad soyad"
                            />
                          </div>
                          <div className="col-xl-6 col-12 mb-xl-0 mb-3">
                            <input
                              className="w-100"
                              type="text"
                              placeholder="Şəxsiyyətin nömrəsi"
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-xl-6 col-12 mb-xl-0 mb-3">
                            <InputMask
                              mask="+\9\9\4 99 999 99 99"
                              maskChar=""
                              className="w-100"
                              type="text"
                              placeholder="Əlaqə nömrəsi"
                            />
                          </div>
                          <div className="col-xl-6 col-12 mb-xl-0 mb-3">
                            <input
                              className="w-100"
                              type="text"
                              placeholder="Şəxsiyyət seriyası"
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-xl-6 col-12 mb-xl-0 mb-3">
                            <input
                              className="w-100"
                              type="text"
                              placeholder="Məbləğ (AZN)"
                            />
                          </div>
                          <div className="col-xl-6 col-12 mb-xl-0 mb-3 d-flex align-items-center justify-content-between">
                            <div>
                              <span>
                                Layf fonduna ayırmaq istədiyiniz məbləğ
                              </span>
                            </div>
                            <select name="" id="">
                              <option value="">15 %</option>
                              <option value="">20 %</option>
                              <option value="">25 %</option>
                              <option value="">30 %</option>
                            </select>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="links d-flex  align-items-center">
                      <button>
                        <Link to="/payment">Donate for the cause</Link>
                      </button>
                      <button>
                        <Link to="/">LEARN MORE</Link>
                      </button>
                    </div>
                  </Toast.Body>
                </Toast>
              </div>

              {/*<div className="d-flex align-items-center">*/}
              {/*  <div className="donor d-flex w-50">*/}
              {/*    <div>*/}
              {/*      <img src={Image.ProfilePic} alt="" />*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <img src={Image.ProfilePic} alt="" />*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <img src={Image.ProfilePic} alt="" />*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <img src={Image.ProfilePic} alt="" />*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <img src={Image.ProfilePic} alt="" />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="donor-link">*/}
              {/*    <Link to="/">Onlardan biri ol</Link>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <div className="col-lg-6 col-12 animated fadeInRightBig">
              <div className="donate-slider-links d-flex float-right align-items-center">
                <Link>
                  <p className="line-link">PHOTO</p>
                </Link>
                <Link>
                  <p className="line-link">VIDEO</p>
                </Link>
                <div className="d-flex">
                  <div
                    className="mr-2"
                    onClick={() => {
                      previous();
                    }}
                  >
                    <img src={Image.Back} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      next();
                    }}
                  >
                    <img src={Image.Next} alt="" />
                  </div>
                </div>
              </div>
              <div className="donate-leaf">
                <img src={Image.Leaf2} alt="" />
              </div>
              <div className="donate-slider2">
                <Slider arrows={false} asNavFor={nav2} ref={slider1}>
                  {supportFull?.images?.map((p, i) => (
                    <div className="donate-slider1-box">
                      <img src={p.url} alt="" />
                      <div className="lay"></div>
                    </div>
                  ))}
                  {supportFull?.videos?.map((p, i) => (
                    <div className="donate-slider1-box">
                      {/*<video src={p.url} alt="" />*/}
                      <video loop autoPlay={true} muted={true} controls>
                        <source src={p.url} type="video/mp4"></source>
                      </video>
                      <img src={Image.Play} alt="" className="play" />
                      {/*<div className="lay"></div>*/}
                    </div>
                  ))}
                </Slider>

                <Slider
                  asNavFor={nav1}
                  ref={slider2}
                  slidesToShow={3}
                  swipeToSlide={false}
                  focusOnSelect={true}
                  arrows={false}
                  className="mt-2"
                >
                  {supportFull?.images?.map((p, i) => (
                    <div key={i} className="donate-slider2-box">
                      <img src={p.url} alt="" />
                      <div className="lay"></div>
                    </div>
                  ))}
                  {supportFull?.videos?.map((p, i) => (
                    <div className="donate-slider2-box">
                      {/*<video src={p.url} alt="" />*/}
                      <video loop autoPlay={true} muted={true} controls>
                        <source src={p.url} type="video/mp4"></source>
                      </video>
                      <img src={Image.Play} alt="" className="play2" />
                      {/*<div className="lay"></div>*/}
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          <div>
            <img src={Image.Donation} alt="" className="donation" />
          </div>
          <Social />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ supportFull }) => {
  return { supportFull };
};

export default connect(mapStateToProps, { getSupportFull })(DonateNow);
