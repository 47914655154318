import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Image from "../../../assets/img/Images";
import siteUrl from "../../../const/url";
import {connect} from "react-redux";
import {getSubject} from "../../../redux/actions";
import {useTranslation} from "react-i18next";

function SupportLayf(props) {
    const {t} = useTranslation()
    const { getSubject, subject } = props
    useEffect(() => {
        if (subject.length === 0) {
            getSubject();
        }
    }, [subject])

    const perPage = 4;
    const [page, setPage] = useState(0);
    const [supportList, setSupportList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getSupportList = () => {
            setLoading(true);
            fetch(`${siteUrl}api/GetSupport?GetDataCount=${perPage}&PerDataCount=${page}`)
                .then(res => res.json())
                .then(res => {
                    setSupportList([...supportList, ...res]);
                    setLoading(false);
                });
        }
        getSupportList();
    }, [page]);

    return (
        <div>
            <div className="main-100 position-relative">
                <div className="container-fluid">
                    <div className="row justify-content-between mt-5 align-items-center">
                        <div className="col-lg-6 section-text mt-5">
                            <h2>Dəstək ver</h2>
                        </div>
                        <div className="col-lg-6 support-links d-flex justify-content-end">
                            <ul className="d-flex">
                                <li className="line-link">hamisi</li>
                                {subject.map(s => (
                                    <li className="line-link">{s.name}</li>
                                ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="payment-leaf1">
                        <img src={Image.Leaf2} alt=""/>
                    </div>
                    <div>
                        <img src={Image.OurCauses} alt="" className="donation"/>
                    </div>
                    <div className="support-gallery animated zoomIn">
                        <div className="row">

                            {
                                supportList.map((s, i) => (
                                    <div className="col-xl-3 col-lg-4 col-12 mb-5">
                                        <div className="box">
                                            <div>
                                                <img src={s.photo?.url} alt=""/>
                                            </div>
                                            <div className="box-text">
                                                <span>{s.title}</span>
                                                <p>{s.description}</p>
                                            </div>
                                            <div className="box-range">
                                                <div
                                                    className="range-percent d-flex justify-content-between align-items-center">
                                                    <p>Total collection</p>
                                                    <span>{s.accumulatedAmount > s.neededAmount ? "100" : Math.floor(s.accumulatedAmount / s.neededAmount * 100)} %</span>
                                                </div>
                                                <Form>
                                                    <Form.Group controlId="formBasicRange">
                                                        <Form.Control type="range"
                                                                      value={Math.round(s.accumulatedAmount / s.neededAmount * 100)}/>
                                                    </Form.Group>
                                                </Form>
                                                <div className="d-flex justify-content-between range-about">
                                                    <p>Collection: ${s.accumulatedAmount}</p>
                                                    <p>Goal: ${s.neededAmount}</p>
                                                </div>
                                            </div>
                                            <div className="box-link">
                                                <Link to={`/donate-now/${s.id}`}><p>Donate</p></Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <div className="links d-flex align-items-center justify-content-center mb-4">
                            { supportList.length  >= perPage ? <button className="btn-load-more" onClick={() => setPage(page + perPage)}>{loading ? 'Loading...' : t('loadMore')}</button> : " " }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ subject }) => {
    return { subject };
}

export default connect(mapStateToProps, { getSubject })(SupportLayf);

