import React from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import { useTranslation } from 'react-i18next';


function Donates(props) {
    const {t} = useTranslation()
    return (
        <div>
            <div className="main position-relative">
                <div className="container-fluid">
                    <div className="row justify-content-between mt-5 align-items-center">
                        <div className="section-text mt-5 col-lg-6">
                            <h2>Hesabatlıq</h2>
                        </div>
                        <div className="support-links col-lg-6 d-flex justify-content-end">
                            <ul className="d-flex">
                                <li className="line-link">Şirkət</li>
                                <li className="line-link">Fərdi şəxs</li>
                            </ul>
                        </div>
                    </div>
                    <div className="donates animated zoomIn">
                        <div className="d-xl-flex d-block justify-content-between text-right">
                            <div className="animated fadeInLeftBig mx-2 mt-xl-0 mt-4 donates-board d-sm-flex text-center justify-content-between align-items-center">
                                <div className="d-sm-flex d-block mb-sm-0 mb-3">
                                    <p>1</p>
                                    <span>Dost Sığorta Azərbaycan</span>
                                </div>
                                <div className="mb-sm-0 mb-3">
                                    <span>Yardım məbləğ</span>
                                    <p className="price">300 AZN</p>
                                </div>
                                <div className="mb-sm-0 mb-3">
                                    <Link to="/donate-now">
                                        <span>Aksiya</span>
                                        <span>Səhifə</span>
                                    </Link>
                                </div>
                                <div className="mb-sm-0 mb-3">
                                    <p>Tarix</p>
                                    <span>07 May 2020, 00:45</span>
                                </div>
                            </div>
                            <div className="animated fadeInLeftBig mx-2 mt-xl-0 mt-4 donates-board d-sm-flex text-center justify-content-between align-items-center">
                                <div className="d-sm-flex d-block mb-sm-0 mb-3">
                                    <p>1</p>
                                    <span>Dost Sığorta Azərbaycan</span>
                                </div>
                                <div className="mb-sm-0 mb-3">
                                    <span>Yardım məbləğ</span>
                                    <p className="price">300 AZN</p>
                                </div>
                                <div className="mb-sm-0 mb-3">
                                    <Link to="/donate-now">
                                        <span>Aksiya</span>
                                        <span>Səhifə</span>
                                    </Link>
                                </div>
                                <div>
                                    <p>Tarix</p>
                                    <span>07 May 2020, 00:45</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="links d-flex align-items-center justify-content-center my-4">
                                <Link to="/">{t('loadMore')}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="payment-leaf1 ">
                        <img src={Image.Leaf2} alt=""/>
                    </div>
                    <div>
                        <img src={Image.Yardimlar} alt="" className="donation"/>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Donates;
