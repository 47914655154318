import React , {useState , useEffect} from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import { withTranslation } from "react-i18next";
import {connect} from "react-redux";
import {changeLanguage} from "../../../redux/actions";
import Image from '../../../assets/img/Images';

const  Lang = (props) => {
    const [lang, setLang] = useState("az");
    let newLang = localStorage.getItem('locale')
    const changeLang = lang => {
        const { i18n } = props;
        localStorage.setItem("locale", lang);
        props.changeLanguage(lang);
        setLang(lang);
        // for creating new request
        setTimeout(()=>{
            props.changeLanguage(lang);
        } , 10)
        window.location.reload()
    };

    useEffect(() => {
        setLang(
            localStorage.getItem("locale") ? localStorage.getItem("locale") : "az"
        );
    } , [lang])

    return (
        <div className="lang">
            <Dropdown className="d-xl-inline-block">
                <Dropdown.Toggle className='text-white'  variant='text' id="dropdown-basic">
                    <img src={newLang === 'az' ? Image.Aze : newLang === 'ru' ? Image.Rus : Image.Eng }/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    { newLang !== 'az' &&
                    <Dropdown.Item onClick={()=>{changeLang('az')} }  className={'animated fadeIn'} ><img src={Image.Aze}/></Dropdown.Item>
                    }
                    { newLang !== 'ru' &&
                    <Dropdown.Item onClick={()=>{changeLang('ru')} }  className={'animated fadeIn'} ><img src={Image.Rus}/></Dropdown.Item>
                    }
                    { newLang !== 'en' &&
                    <Dropdown.Item onClick={()=>{changeLang('en')} } className={'animated fadeIn'} ><img src={Image.Eng}/></Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default connect(null, { changeLanguage })(withTranslation()(Lang));

