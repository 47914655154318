import React from 'react';
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Image from "../../../assets/img/Images";
import { useTranslation } from 'react-i18next';

function SupportLayf(props) {
    const { t  } = useTranslation()
    return (
        <div>
            <div className="main position-relative">
                <div className="container-fluid">
                    <div className="row justify-content-between align-items-center mt-5 ">
                        <div className="col-lg-6 section-text mt-5">
                            <h2>Make their dream come true</h2>
                            <span>
                               Compete against other talented typists around the globe and show where the best typists  completing races and collecting points.
                            </span>
                        </div>
                        <div className="col-lg-6 support-links d-flex justify-content-end">
                            <ul className="d-flex">
                                <li className="line-link">ALL</li>
                                <li className="line-link">MEDECINE</li>
                                <li className="line-link">EDUCATION</li>
                                <li className="line-link">CLEANING</li>
                            </ul>
                        </div>
                    </div>
                    <div className="payment-leaf1">
                        <img src={Image.Leaf2} alt=""/>
                    </div>
                    <div>
                        <img src={Image.OurCauses} alt="" className="donation"/>
                    </div>
                    <div className="support-gallery animated zoomIn">
                        <div className="row mb-4">
                            <div className="col-xl-3 col-lg-4 col-12 mb-5">
                                <div className="box">
                                    <div>
                                        <img src={Image.Photo7} alt=""/>
                                    </div>
                                    <div className="box-text">
                                        <span>Hide Heart Attack</span>
                                        <p>
                                    Hello friends of Hide, On September 28th, my husband Hide suddenly had a heart attack while going to coronavirus...
                                    </p>
                                    </div>
                                    <div className="box-range">
                                        <div className="range-percent d-flex justify-content-between align-items-center">
                                            <p>Total collection</p>
                                            <span>80 %</span>
                                        </div>
                                        <Form>
                                            <Form.Group controlId="formBasicRange">
                                                <Form.Control type="range"/>
                                            </Form.Group>
                                        </Form>
                                        <div className="d-flex justify-content-between range-about">
                                            <p>Collection: $8.000</p>
                                            <p>Goal: $10.000</p>
                                        </div>
                                    </div>
                                    <div className="box-link">
                                        <Link to="/donate-now"><p>Donate</p></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-12 mb-5">
                                <div className="box">
                                    <div>
                                        <img src={Image.Photo7} alt=""/>
                                    </div>
                                    <div className="box-text">
                                        <span>Hide Heart Attack</span>
                                        <p>
                                            Hello friends of Hide, On September 28th, my husband Hide suddenly had a heart attack while going to coronavirus...
                                        </p>
                                    </div>
                                    <div className="box-range">
                                        <div className="range-percent d-flex justify-content-between align-items-center">
                                            <p>Total collection</p>
                                            <span>80 %</span>
                                        </div>
                                        <Form>
                                            <Form.Group controlId="formBasicRange">
                                                <Form.Control type="range"/>
                                            </Form.Group>
                                        </Form>
                                        <div className="d-flex justify-content-between range-about">
                                            <p>Collection: $8.000</p>
                                            <p>Goal: $10.000</p>
                                        </div>
                                    </div>
                                    <div className="box-link">
                                        <Link to="/donate-now"><p>Donate</p></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-12 mb-5">
                                <div className="box">
                                    <div>
                                        <img src={Image.Photo7} alt=""/>
                                    </div>
                                    <div className="box-text">
                                        <span>Hide Heart Attack</span>
                                        <p>
                                            Hello friends of Hide, On September 28th, my husband Hide suddenly had a heart attack while going to coronavirus...
                                        </p>
                                    </div>
                                    <div className="box-range">
                                        <div className="range-percent d-flex justify-content-between align-items-center">
                                            <p>Total collection</p>
                                            <span>80 %</span>
                                        </div>
                                        <Form>
                                            <Form.Group controlId="formBasicRange">
                                                <Form.Control type="range"/>
                                            </Form.Group>
                                        </Form>
                                        <div className="d-flex justify-content-between range-about">
                                            <p>Collection: $8.000</p>
                                            <p>Goal: $10.000</p>
                                        </div>
                                    </div>
                                    <div className="box-link">
                                        <Link to="/donate-now"><p>Donate</p></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-12 mb-5">
                                <div className="box">
                                    <div>
                                        <img src={Image.Photo7} alt=""/>
                                    </div>
                                    <div className="box-text">
                                        <span>Hide Heart Attack</span>
                                        <p>
                                            Hello friends of Hide, On September 28th, my husband Hide suddenly had a heart attack while going to coronavirus...
                                        </p>
                                    </div>
                                    <div className="box-range">
                                        <div className="range-percent d-flex justify-content-between align-items-center">
                                            <p>Total collection</p>
                                            <span>80 %</span>
                                        </div>
                                        <Form>
                                            <Form.Group controlId="formBasicRange">
                                                <Form.Control type="range"/>
                                            </Form.Group>
                                        </Form>
                                        <div className="d-flex justify-content-between range-about">
                                            <p>Collection: $8.000</p>
                                            <p>Goal: $10.000</p>
                                        </div>
                                    </div>
                                    <div className="box-link">
                                        <Link to="/donate-now"><p>Donate</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="links d-flex align-items-center justify-content-center my-4">
                                <Link to="/">{t('loadMore')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default SupportLayf;
