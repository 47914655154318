import React from "react";
import Image from "../../assets/img/Images";
export const Inform = (
      { description, imgUrl, imgPlacedRight }
) => {
      return (
            <div className="position-relative animated fadeIn">
                  <div className="container-fluid">
                        <div className="row pb-5 animated zoomIn">
                              <div style={{ order: imgPlacedRight ? 1 : 2 }} className="col-xl-6 col-12">
                                    <div className="section-text ml-5">
                                          <div>
                                                <p className="desc"
                                                      dangerouslySetInnerHTML={{
                                                            __html: description
                                                      }}
                                                />
                                          </div>
                                    </div>
                              </div>
                              <div style={{ order: imgPlacedRight ? 2 : 1 }} className="col-xl-6 col-12 d-lg-block d-none section-one-leaf position-relative animated fadeInRightBig">
                                    <div className="leaf">
                                          <img src={imgUrl} alt="" className="pic" />
                                    </div>
                                    {/* <div className={`${imgPlacedRight ? 'leafRight' : 'leafLeft'}`}>
                                          <img src={Image.Leaf2} alt="" />
                                    </div> */}
                              </div>
                        </div>
                        <div>
                              <img src={Image.AboutUs} alt="" className="donation" />
                        </div>

                  </div>
            </div>

      )
}

export default Inform