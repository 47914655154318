import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import {connect} from "react-redux";
import {getSubject} from "../../../redux/actions";
import vys from "../../../const/api"
import {ProgressBar} from "react-bootstrap"
import InputMask from 'react-input-mask';

// const initialMyState = {
//     seria: '',
//     neededAmount: ''
// }

function ApplyForm(props) {
    // const [mystate, setMyState] = useState({...initialMyState})
    const [fileList , setFileList] = useState([])
    const [fullName, setFullName ] = useState('')
    const [pin, setPin ] = useState('')
    const [neededAmount, setNeededAmount ] = useState('')
    const [subjectId, setSubjectId] = useState(0)
    const [accumulatedAmount, setAccumulatedAmount] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [phone, setPhone] = useState('')
    const [subjects, setSubjects] = useState('')
    const [adress, setAdress] = useState('')
    const [description, setDescription] = useState('')
    const [title, setTitle] = useState('')
    const [showSuccess, setshow] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [pinError, setPinError] = useState(false);
    const [neededAmountError, setNeededAmountError] = useState(false);
    const [accumulatedAmountError, setAccumulatedAmountError] = useState(false);
    const [startDateError, setStartDateError] = useState('')
    const [endDateError, setEndDateError] = useState('')
    const [phoneError, setPhoneError] = useState(false);
    const [subjectsError, setSubjectsError] = useState(false)
    const [adressError, setAdressError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [titleError, setTitleError] = useState('')
    const [progress, setProgress] = useState(0);

    const showmessage = (set) => {
        set(true)
        setTimeout(function () {
            set(false)
        }, 2000);
    }
    const inputName = [
        { n: fullName, error: nameError, setError: setNameError, setValue: setFullName, placeholder: 'Ad, Soyad *', type: 'text' },
    ]
    const inputsAmount = [
        { n: neededAmount, error: neededAmountError, setError: setNeededAmountError, setValue: setNeededAmount, placeholder: 'Lazım olan məbləğ (AZN) *', type: 'text' },
        { n: accumulatedAmount, error: accumulatedAmountError, setError: setAccumulatedAmountError, setValue: setAccumulatedAmount, placeholder: 'Yığılmış məbləğ (AZN) *', type: 'text' },
    ]
    const inputPin = [
        { n: pin, error: pinError, setError: setPinError, setValue: setPin, placeholder: 'FIN kod *', type: 'text' },
    ]
    const inputsDate = [
        { n: startDate, error: startDateError, setError: setStartDateError, setValue: setStartDate, placeholder: 'Başlama tarixi', type: 'date' },
        { n: endDate, error: endDateError, setError: setEndDateError, setValue: setEndDate, placeholder: 'Bitmə tarixi', type: 'date' },
    ]
    const inputsPhone = [
        { n: phone, error: phoneError, setError: setPhoneError, setValue: setPhone, placeholder: 'Telefon nömrəsi *', type: 'phone' },
    ]
    const inputsSubjects = [
        { n: subjects, error: subjectsError, setError: setSubjectsError, setValue: setSubjects, placeholder: 'Mövzular', type: 'select' },
    ]
    const inputsOther = [
        { n: adress, error: adressError, setError: setAdressError, setValue: setAdress, placeholder: 'Ünvan *', type: 'text' },
        { n: title, error: titleError, setError: setTitleError, setValue: setTitle, placeholder: 'Başlıq *', type: 'text' },
        { n: description, error: descriptionError, setError: setDescriptionError, setValue: setDescription, placeholder: 'Mesajınız *', type: 'text' },
    ]
    const inputValidation = () => {
        let sendM = false
        inputName.map((i) => {
            if (i.n.trim() === null || i.n.trim() === "" || i.n === " ") {
                showmessage(i.setError)
                sendM = false
            } else {
                sendM = true
            }
        })
        inputPin.map((input1) => {
            if (input1.n.trim() === null || input1.n.trim() === "" || input1.n === " ") {
                showmessage(input1.setError)
                sendM = false
            } else {
                sendM = true
            }
        })
        inputsAmount.map((input2) => {
            if (input2.n.trim() === null || input2.n.trim() === "" || input2.n === " ") {
                showmessage(input2.setError)
                sendM = false
            } else {
                sendM = true
            }
        })
        inputsPhone.map((input2) => {
            if (input2.n.trim() === null || input2.n.trim() === "" || input2.n === " ") {
                showmessage(input2.setError)
                sendM = false
            } else {
                sendM = true
            }
        })
        inputsOther.map((input3) => {
            if (input3.n.trim() === null || input3.n.trim() === "" || input3.n === " ") {
                showmessage(input3.setError)
                sendM = false
            } else {
                sendM = true
            }
        })
        sendMessage(sendM)
    }
    const onsubmit = (e) => {
        e.preventDefault()
        inputValidation()
        deleteFile()

    }
    const sendMessage = (sendM) => {
        if (sendM) {
            vys
                .post("GetSupport", {
                    fullName,
                    pin,
                    neededAmount: Number(neededAmount),
                    accumulatedAmount: Number(accumulatedAmount),
                    startDate,
                    endDate,
                    phone,
                    adress,
                    title,
                    description,
                    images: fileList.filter(fileLists => fileLists.type === "image/jpeg"),
                    videos: fileList.filter(fileLists => fileLists.type === "video/mp4"),
                    subjectId: Number(subjectId),

                })
                .then((res) => {
                    showmessage(setshow)
                    setFullName('');
                    setPin('');
                    setNeededAmount('');
                    setAccumulatedAmount('');
                    setStartDate('');
                    setEndDate('');
                    setPhone('');
                    setAdress('');
                    setDescription('');
                    setTitle('asas')
                })
                .catch((err) => {
                    console.log(err)
                });
        }
    }

    const deleteFile = (i) => {
        let arr = [...fileList]
        arr.splice(i , 1)
        setFileList(arr)
    }
    const onUpload = e => {
        const index = fileList.length;
        let inserted = false;
        let form_data = new FormData();
        form_data.append("_file", e.target.files[0]);
        const files = [...fileList];
        files.push({
            uploadedFile: false
        });
        setFileList(files);
        vys
            .post("Upload/File", form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },

                onUploadProgress: data => {
                    const percent = Math.round(90 * data.loaded / data.total);
                    setProgress(percent);
                },
            })
            .then((res) => {
                const arr = [...fileList];
                arr.splice(index, 1, {
                    ...res.data,
                    uploadedFile: true,
                });
                setProgress(0);
                setFileList(arr);
            })
            .catch((err) => {
                console.log(err)
            });
    }
    const { getSubject, subject } = props
    useEffect(() => {
        if (subject.length === 0) {
            getSubject();
        }
    }, [subject])


    return (
        <div>
            <div className="section-contact main position-relative">
                <div className="container-fluid">
                    <div className="d-flex justify-content-end cancel">
                        <Link to="/">
                            <img src={Image.Close} alt="" />
                        </Link>
                    </div>
                    <div className="text-center contact-text">
                        <>
                            <form onSubmit={onsubmit}>
                                {showSuccess ? <h1 className="text-white animated fadeIn"><strong>Müraciətiniz qəbul olunmuşdur</strong></h1> : <h1><strong>Müraciət forması</strong></h1>}

                                <div className="row">
                                    {inputName.map((inputName) => (
                                        <div className="col-md-6 position-relative">
                                            <div>
                                                <input placeholder={inputName.placeholder} value={inputName.n} className={`${inputName.error ? 'error' : 'mb-3'}`} type={inputName.type} onChange={(e) =>
                                                {inputName.setValue(e.target.value)}} />
                                                {inputName.error &&
                                                    <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span>}
                                            </div>
                                        </div>
                                    ))}
                                    {inputPin.map((inputPin) => (
                                        <div className="col-md-6 position-relative">
                                            <div>
                                                <InputMask placeholder={inputPin.placeholder} {...props} mask="*******" maskChar=""  value={inputPin.n} className={`${inputPin.error ? 'error' : 'mb-3'}`} type={inputPin.type} onChange={(e) =>
                                                {inputPin.setValue(e.target.value.toUpperCase())}} />
                                                {inputPin.error &&
                                                <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="row">
                                    {inputsAmount.map((inputAmount) => (
                                        <div className="col-md-6 position-relative">
                                            <div>
                                                <input placeholder={inputAmount.placeholder}  value={inputAmount.n} className={`${inputAmount.error ? 'error' : 'mb-3'}`} type={inputAmount.type} onChange={(e) =>
                                                {inputAmount.setValue(e.target.value)}} />
                                                {inputAmount.error &&
                                                <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="row">
                                    {inputsDate.map((inputDate) => (
                                        <div className="col-md-6 position-relative">
                                            <div>
                                                <input value={inputDate.n} className={`${inputDate.error ? 'error' : 'mb-3'}`} type={inputDate.type}
                                                    onChange={(e) => {
                                                        inputDate.setValue(e.target.value)
                                                    }} placeholder={inputDate.placeholder}
                                                />
                                                {inputDate.error &&
                                                    <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="row align-items-baseline">
                                    {inputsPhone.map((inputPhone) => (
                                        <div className="col-md-6 col-12 position-relative">
                                            <div>
                                                <InputMask placeholder={inputPhone.placeholder} {...props} mask="+\9\9\4 99 999 99 99" maskChar="" value={inputPhone.n} className={`${inputPhone.error ? 'error' : 'mb-3'}`} type={inputPhone.type} onChange={(e) =>
                                                {inputPhone.setValue(e.target.value)}} />
                                                {inputPhone.error &&
                                                    <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span>}
                                            </div>
                                        </div>
                                    ))}
                                    { inputsSubjects.map((i) => (
                                        <div className="col-md-6 col-12 select">
                                            <select onChange={(e) =>
                                            {setSubjectId(e.target.value)}} name="" id=""  className="form-select">
                                                <option  className="d-none">{i.placeholder}</option>
                                                {
                                                    subject.filter((s) => {
                                                        return s.subject_CategoryId === 0
                                                    }).map((s, i) => (
                                                        <option key={i} value={s.id}>{s.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    ))

                                    }
                                </div>
                                <div className="row">
                                    {inputsOther.map((inputOther) => (
                                        <div className="col-12 position-relative mt-md-0 mt-3">
                                            <div>
                                                <input value={inputOther.n} className={`${inputOther.error ? 'error' : 'mb-3'}`} type={inputOther.type}
                                                    onChange={(e) => {
                                                        inputOther.setValue(e.target.value)
                                                    }} placeholder={inputOther.placeholder}
                                                />
                                                {inputOther.error &&
                                                    <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span>}
                                            </div>
                                        </div>
                                    ))

                                    }
                                </div>

                                <div className="contact-link d-md-flex justify-content-center">
                                    <label className="upload-file mb-md-0 mb-3" htmlFor="upload-photo">Şəkil və video əlavə etmək</label>
                                    <input disabled={progress} onChange={(e) => {
                                        onUpload(e)
                                    }} type="file" name="cv" id="upload-photo" />
                                    <button type='submit' >Göndər</button>
                                </div>
                                <div className="file-upload-box">
                                    {fileList.length > 0 &&
                                    fileList.map((f, i)=>(
                                        <React.Fragment key={i}>
                                            <div className="animated zoomIn progress-file d-flex justify-content-between align-items-center p-3 my-2">
                                                <div className="uploaded-file mr-4 ">
                                                    {f.uploadedFile ? <img src={f.url} alt=""/> : <img src={Image.Avatar} alt=""/>}
                                                </div>
                                                <div className="mr-4 w-100">
                                                    {f.uploadedFile ? (
                                                        <ProgressBar now={100} label={`100%`} />
                                                    ) : (
                                                        <ProgressBar now={progress} label={`${progress}%`} />
                                                    )}
                                                </div>
                                                <div onClick={()=>{deleteFile(i)}} className="delete-file">
                                                    <div>
                                                        <img src={Image.Close} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))
                                    }
                                </div>
                            </form>
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ subject }) => {
    return { subject };
}

export default connect(mapStateToProps, { getSubject })(ApplyForm);
