import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import Image from "../../../assets/img/Images";
import Staff from "../Staff/Staff";
import Social from "../../Elements/Social/Social";
import { getAbout, getYears } from "../../../redux/actions";
import { connect } from "react-redux";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Slider from "react-slick";
import Inform from '../../Inform/Inform';

function About(props) {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [data, setData] = useState(0)
    const yearSlider = useRef();
    const yearSliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
    };

    const next = () => {
        yearSlider.current.slickNext();
    }
    const previous = () => {
        yearSlider.current.slickPrev();
    }

    const { about, getAbout } = props
    const { years, getYears } = props
    useEffect(() => {
        let mounted = true
        if (about.length === 0) {
            getAbout();
        }
        if (years.length === 0) {
            getYears();
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <div>
            <div className="margin-t">
                <Tabs defaultActiveKey="about" id="uncontrolled-tab-example" className="mb-3 px-5 ">
                    <Tab eventKey="about" title="HAQQIMIZDA">
                        <div className="our-mission">
                            <div className="section-text ml-5 mt-5 pt-5 pl-3 animated fadeInLeft">
                                <h2>Haqqımızda</h2>
                            </div>
                            {
                                about.map((item, index) => (
                                    <Inform
                                        key={index}
                                        description={item.about}
                                        imgUrl={item.photo.url}
                                        imgPlacedRight={index % 2 === 0}
                                    />
                                ))
                            }
                        </div>
                    </Tab>
                    <Tab eventKey="works" title="GÖRÜLƏN İŞLƏR">
                        <div className="our-mission position-relative animated fadeIn">
                            <div className="container-fluid">
                                <div className="row h-100 mt-5 pt-5 animated zoomIn">
                                    <div className="col-xl-6 col-12">
                                        <div className="section-text">
                                            <h2>Bizim işlərimiz</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className='year-slider d-flex align-items-center'>
                                    {
                                        years.length > 10 &&
                                        <button onClick={() => {
                                            previous()
                                        }} className="prev animated fadeInLeftBig">
                                            <img src={Image.BackTrack} alt="" />
                                        </button>
                                    }
                                    {
                                        years.length <= 10 ?
                                            <div className="d-flex years-table">
                                                {years.length ?
                                                    years.map((y, index) => (
                                                        <div onClick={() => {
                                                            setSelectedIndex(index)
                                                        }}
                                                            className={`mr-md-5 animated fadeInDown cursor-pointer number d-block ${selectedIndex === index ? 'active text-white fa-2x' : ''} `}>
                                                            <p className="line-date">{y.years}</p>
                                                        </div>
                                                    ))
                                                    : ''
                                                }
                                            </div> :
                                            <Slider
                                                className={'aboutSlider'}
                                                ref={slider => (yearSlider.current = slider)}
                                                {...yearSliderSettings}>
                                                {years.length ?
                                                    years.map((y, index) => (
                                                        <div onClick={() => {
                                                            setSelectedIndex(index)
                                                        }}
                                                            className={`animated fadeInUp cursor-pointer slider-numb line-date d-block ${selectedIndex === index ? 'active text-white fa-2x' : ''} `}>
                                                            <p className="line-date">{y.years}</p>
                                                        </div>
                                                    ))
                                                    : ''
                                                }

                                            </Slider>
                                    }

                                    {
                                        years.length > 10 &&
                                        <button onClick={() => {
                                            next()
                                        }} className="next animated fadeInRightBig">
                                            <img src={Image.BackTrack} alt="" />
                                        </button>
                                    }
                                </div>

                                <div>
                                    {(years.length && years[selectedIndex].actions) ?
                                        years[selectedIndex].actions.map((item, index) => (
                                            <Inform
                                                key={index}
                                                description={item.text}
                                                imgUrl={item.image_url}
                                                imgPlacedRight={index % 2 === 0}
                                            />
                                        )) : ''
                                    }
                                </div>
                                <Social />
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="staff" title="İDARƏ HEYYƏTİ">
                        <Staff />
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

const mapStateToProps = ({ about, years }) => {
    return { about, years };
}

export default connect(mapStateToProps, { getAbout, getYears })(About);
